<template>
  <v-row class="mainContainer">
    <div class="sectionLeft">
      <h1>Sistema de Conferência de Saúde do Estado da Bahia</h1>
      <p>
        Sistema de Conferências de Saúde do Estado da Bahia que permite o
        gerenciamento de Conferências ocorridas no território do estado com o
        propósito de registrar as propostas discutidas, delegados e demais
        participações no campo da Saúde que serão consolidadas e encaminhadas
        aos órgãos públicos competentes.
      </p>
    </div>
    <v-col class="sectionRight" cols="12" sm="12" md="3">
      <div class="boxLogo">
        <h3 class="mb-1">SCSEB</h3>
        <span>SISTEMA DE CONFERÊNCIAS DE SAÚDE DO ESTADO DA BAHIA</span>
      </div>

      <ErrorLogin :resource="errorLogin" />

      <v-form
        @submit.prevent="login"
        v-model="valid"
        lazy-validation
        ref="form"
        class="form-login"
      >
        <label for="cpf">Login <span class="validat-label">*</span></label>
        <v-text-field
          id="cpf"
          placeholder="000.000.000-00"
          outlined
          dense
          v-model="cpf"
          :rules="cpfRules"
          required
          v-mask="'###.###.###-##'"
          class="input-ajust"
        ></v-text-field>

        <label for="password">Senha <span class="validat-label">*</span></label>
        <v-text-field
          label="Senha"
          id="password"
          placeholder="Digite sua senha"
          outlined
          dense
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
          v-model="password"
          :rules="passRules"
          class="input-ajust"
          required
        ></v-text-field>
        <div class="captchaGrid">
          <div class="captcha-top">
            <img :src="img" alt="" />
            <v-btn
              out
              elevation="0"
              class="btn-models"
              height="25px"
              width="10px"
              @click="initialize()"
            >
              <v-icon dense color="black">mdi-refresh</v-icon>
            </v-btn>
          </div>
          <div class="space"></div>
        </div>
        <v-text-field
          placeholder="O que você consegue ler na imagem?"
          outlined
          dense
          class="input-captcha-bg"
          v-model="captcha"
          :rules="captchaRules"
          v-mask="'####'"
          required
        ></v-text-field>
        <v-btn
          type="submit"
          class="mb-5 text-none btn-input"
          block
          color="blue"
          elevation="1"
          large
          :loading="carregamento"
          :disabled="desativado"
          >{{ btnStatus }}</v-btn
        >
      </v-form>
      <ResetPass :resource="alterPassword" />
      <span class="mt-10" style="margin-bottom:-30px;"> <em>Versão {{version}} </em>  </span>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import config from "../../config/config";
import ErrorLogin from "../alerts/ErrorLogin-comp.vue";
import ResetPass from "./NewPassword-comp.vue";

export default {
  data() {
    return {
      version: config.version,
      valid: true,
      showPass: false,
      cpf: "",
      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],
      password: "",
      passRules: [
        (v) => !!v || "A senha é obrigatória",
        (v) => (v && v.length <= 16) || "Você excedeu o tamanho máximo.",
      ],
      captcha: "",
      captchaRules: [
        (v) => !!v || "O captcha é obrigatório",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo.",
      ],
      key: "",
      img: "",
      btnStatus: "Acessar",
      carregamento: false,
      desativado: false,
      errorLogin: [],
      alterPassword: {
        btnLogin: "Esqueci minha senha",
        dialogPass: false,
      },
    };
  },

  components: {
    ErrorLogin,
    ResetPass,
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios.get(`${config.captchaURL}`).then((response) => {
        this.key = response.data.key;
        this.img = response.data.img;
      });
    },

    login() {
      if (this.$refs.form.validate() == true) {
        const newCPF = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
        const dados = new Object();
        dados.cpf = newCPF;
        dados.password = this.password;
        dados.captcha = this.captcha;
        dados.key = this.key;
        dados.device_name = "sesab";

        axios
          .post(`${config.apiURL}/login`, dados)
          .then((response) => {
            this.carregamento = true;
            this.desativado = true;
            this.$route.meta.voutestar = 'abcd'
            setTimeout(() => {
              this.carregamento = false;
              this.desativado = false;
              this.btnStatus = response.data.message;

              localStorage.setItem("token", response.data.token);
              setTimeout(() => {
                this.$router.push({ name: "Dashboard" });
              });
            });
          })

          .catch((error) => {
            if (error.response.status == 500) {
              this.$swal({
                icon: "error",
                title: error.response.data.details,
                toast: true,
                showConfirmButton: false,
                timer: 3000,
              });
            }

            this.errorLogin = error.response.data.details;
            this.captcha = "";
            this.initialize();
          });
      }
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Poppins";
  border-radius: 0px;
}

.mainContainer {
  margin: 0;
  height: 100vh;
  background-color: #d9afd9;
  background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%);
  background-image: url(../../assets/bgGenerico.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-login {
  width: 95%;
}
.validat-label {
  color: red;
}

.input-ajust {
  margin-top: 10px;
}

.btn-input {
  color: white;
  margin-top: 10px;
}

.input-captcha-bg {
  background: white;
  color: rgb(105, 103, 103);
}

.btn-models {
  width: 20px !important;
}

.sectionLeft {
  width: 30%;
  height: 508px;
  max-height: 508px;
  margin-right: 60px;
}

.sectionLeft p,
.sectionLeft h1 {
  margin-bottom: 10px;
  color: #ffff;
  font-size: 25px;
  letter-spacing: 1px;
}

.sectionLeft p {
  font-size: 16px;
}

.sectionRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 380px;
  padding: 35px 10px;
  background: #fff;
}

.sectionRight > .boxLogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxLogo > h3 {
  font-size: 42px;
  line-height: 26px;
  color: #000;
}

.boxLogo > span {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  text-align: center;
  margin-top: 15px;
}

.captchaGrid {
  display: flex;
  flex-direction: column;
  background: rgba(45, 151, 238, 0.671);
  padding: 10px 5px;
}

.captcha-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.captchaGrid div > img {
  height: 52px;
  width: 100%;
  border: 1px solid transparent;
}

.captchaGrid > .space {
  width: 10px;
}

/* Responsivo */
@media only screen and (max-width: 1380px) {
  /* .sectionLeft {
    flex: 0 0 70%;
    max-width: 70%;
  } */

  .sectionRight {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media only screen and (max-width: 960px) {
  .mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
  }

  .sectionLeft {
    display: none;
  }

  .sectionRight {
    min-width: 400px;
    border-radius: 10px;
  }
}
</style>
